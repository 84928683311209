.cta {
    padding:60px 0;
    background:$primary;

    h3, p {
        color: $white;
        margin-bottom:15px;
    }
}

@media(min-width:$screen-sm) {
    .cta {
        h3 {
            font-size: 40px;
        }
        p {
            font-size:20px;
            margin-bottom:0;
        }
    }
}