.navbar {
    z-index: 1000;
    border-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: none;
    border-bottom: 1px solid $gray-lighter;
    margin-bottom: 0;
    background: $navbar-background;
    min-height: $navbar-height-mobile;

    button {
        margin: $navbar-mobile-button-margin-top $navbar-mobile-button-margin-sides;
        font-size: $navbar-font-size-mobile;
        line-height: $navbar-font-size-mobile;
        border: $navbar-mobile-button-border;
        border-radius: $navbar-mobile-button-border-radius;
        padding: ($navbar-height-mobile - $navbar-font-size-mobile - ($navbar-mobile-button-border-width * 2) - ($navbar-mobile-button-margin-top * 2)) / 2;
    }
}

@media(min-width:$navbar-mobile-breakpoint) {
    .navbar {
        min-height: $navbar-height-desktop;

        .navbar-nav {
            & > li {
                & > a {
                    font-size: $navbar-font-size-desktop;
                    line-height: $navbar-font-size-desktop;
                    padding: ($navbar-height-desktop - $navbar-font-size-desktop) / 2 20px;

                    &:hover {
                        color: $primary;
                    }
                }
            }
        }

        .dropdown-menu {
            & > li {
                & > a {
                    font-size: $navbar-font-size-desktop - 4;
                    line-height: $navbar-font-size-desktop - 4;
                    padding: ($navbar-height-desktop - $navbar-font-size-desktop) / 4 20px;

                    &:hover {
                        color: $primary;
                    }
                }
            }
        }

        .navbar-logo {
            img {
                max-height: $navbar-height-desktop;
            }
        }
    }
}

@media(max-width:$navbar-mobile-breakpoint - 1) {
    .navbar {
        .container {
            width:100%;
        }

        .navbar-collapse {
            position: absolute;
            width: 100%;
            top: $navbar-height-mobile;
            background: $navbar-background;
        }

        .navbar-logo {
            img {
                max-height: $navbar-height-mobile;
            }
        }
    }
}

/* STICKY NAVBAR SETTINGS*/

@mixin sticky-nav {
    margin: 0 auto !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.sticky {
    .navbar {
        @include sticky-nav;
    }

    main {
        @media(min-width:$navbar-mobile-breakpoint) {
            padding-top: $navbar-height-desktop;
        }
    }
}

@media(max-width:$navbar-mobile-breakpoint - 1) {
    header {
        padding-top: $navbar-height-mobile;

        .logo img {
            margin: 0 auto;
        }
    }

    .navbar {
        @include sticky-nav;
    }
}