.services-icons {
    background: $primary;

    h2, h3, p, i {
        color: $white;
    }

    h3 {
        font-weight: 500;
        margin:10px 0;
    }

    i {
        border: 2px solid $white;
        border-radius: 100%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width:36px;
        height:36px;
    }

    .service-icon {
        max-width:350px;
        margin:15px auto;
    }
}

@media(min-width:$screen-sm) {
    .services-icons {
        h2 {
            font-size: 44px;
        }

        h3 {
            font-size:32px;
        }

        p {
            font-size:20px;
        }

        i {
            font-size: 35px;
            width:75px;
            height:75px;      
        }
    }
}