.testimonials {
    background: #F7F7F7;

    .testimonial {
        /*max-width: 350px;*/
        padding: 15px 45px;
    }

    .img-container {
        text-align: center;

        i {
            color: $primary;
            font-size: 50px;
            vertical-align: bottom;

            &.fa-quote-right {
                opacity: 0;
            }
        }

        img {
            border-radius: 100%;
        }
    }

    @media(min-width:$screen-sm) {
        .slick-slider .slick-prev {
            left: -15px;
        }

        .slick-slider .slick-next {
            right: -15px;
        }


        .testimonial {
            padding: 30px;
        }
    }
}

@media(min-width:$screen-sm) {
    .testimonials {
        h2 {
            font-size: 44px;
        }
        p {
            font-size:20px;
        }      
    }
}

@media(max-width:$screen-sm - 1) {
    .testimonials {
        img {
            margin-top:15px;
        }
    }
}