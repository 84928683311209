.slider {
    padding-top: 0;

    .slick-slider {
        margin-bottom:0;
    }

    .img-responsive {
        width: 100%;
    }

    .btn-container {
        display: block;
        text-align: center;
    }

    .text-container {
        text-align: center;

        .container {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }

    .slick-dots li button {
        &:before {
            opacity: 1;
            background: #486a79;
        }

        &[aria-selected=true] {
            &:before {
                background: $primary;
            }
        }
    }
}

@media(min-width:$screen-sm) {
    .slider {
        .btn-container {
            text-align: left;
        }

        .text-container {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            text-align: left;

            .container {
                max-width: 550px;
                background: rgba($white, .8);
            }
        }
        .slick-dots {
            bottom:15px;
        }
    }
}

@media(min-width:$screen-md) {
    .slider {
        .btn-container {
            text-align:right;
        }
        .text-container {     
            h2 {
                font-size:48px;
            }
            p {
                font-size: 20px;
            }
            .container {           
                max-width:750px;
                padding: 25px;
            }
        }
    }
}