.gallery {
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;
    margin: -$gallery-gutter / 2;

    &-item {
        margin: $gallery-gutter / 2 0;
        padding: 0 $gallery-gutter / 2;
        width: calc(50%);

        @media(min-width: $screen-xs) {
            width: calc(33.33%);
        }

        @media(min-width: $screen-md) {
            width: calc(25%);
        }
    }

    &-container {
        background: $gray-lighter;
        padding: 30px 15px;
        margin: $gallery-container-margin;
        display: flex;
        justify-content: center;

        @media(max-width:$screen-sm - 1) {
            margin: $gallery-container-margin-mobile;
        }
    }


    &-title {
        position: absolute;
        bottom: -$gallery-title-font-size;
        left: 0;
        right: 0;
        margin: 0;
        opacity: 0;
        padding: $gallery-title-padding;
        font-size: $gallery-title-font-size;
        line-height: $gallery-title-font-size;
        transition: all .5s;
    }


    a {
        display: block;
        position: relative;
        color: $gallery-font-color;
        overflow: hidden;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transition: .5s background-color;
        }

        &:hover {
            cursor: pointer;

            &:before {
                background-color: $gallery-item-overlay;
            }

            .gallery-title {
                opacity: 1;
                bottom: 0;
            }
        }
    }
}
