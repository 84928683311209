.sub-pages {
    margin: $sub-pages-container-margin;

    img {
        margin: 0 auto;
    }

    a {
        &:hover {
            text-decoration: none;
        }
    }

    .page-title {
        padding: 10px;
        max-width: 500px;
        margin: 0 auto;
    }

    .flex-container {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -7.5px;

        @media(min-width: $screen-md) {
            margin:0 -15px;
        }

        & > div {
            padding: 0 7.5px;
            margin: 7.5px 0;
            width: calc(50%);

            @media(min-width: $screen-xs) {
                width: calc(33.33%);
            }

            @media(min-width: $screen-md) {
                width: calc(25%);
                margin: 15px 0;
                padding: 0 15px;
            }

            .sub-page {
                .page-title {
                    border: 1px solid #E3E3E3;
                    border-top: none;
                }
            }
        }
    }
}
