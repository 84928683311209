.before-and-after {

    &-gallery {
        /*.slick {
            &-next {
                right:-15px;
            }
            &-prev {
                left:-15px !important;
            }
        }*/
    }

    &-gallery-item {
        background:$gray-lighter;
        display:flex!important;
        align-items:center;

        & > li {
            margin: auto;
            display:block;
            padding:15px 0 15px 15px;
            &:last-child {
                padding-right: 15px;
            }
        }
        img {
            
        }
    }
}