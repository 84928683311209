.flex-row {
    margin:0 -15px;
}

.flex-4 {
    display:flex;
    flex-direction:column;
    align-items:center;

    @media(min-width:$screen-xs) {
        flex-direction:row;
        flex-wrap:wrap;
        justify-content:flex-start;
        align-items:flex-start;

        & > * {
            flex:1 1 50%;
            padding:15px;
        }
    }

    @media(min-width:$screen-sm) {
        flex-wrap:nowrap;
    }
}