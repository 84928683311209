$article-image-height-xs: 250px;
$article-image-height-sm: 250px;
$article-image-height-md: 220px;
$article-p-size: 15px;

$author-thumbnail: 50px;
$author-font-size: 14px;


.article-list {
    padding-top:0;

    .col-sm-6:nth-child(2n+1) {
        clear:both;
    }

    .blog-banner {
        min-height:300px;
        background-size:cover;
        background-position-x: center;
        display:flex;
        align-items:center;

        @media(min-width: $screen-md) {
            min-height:400px;
        }

        .blog-title {
            padding:30px;
            background:rgba($black, .5);
            color:$blog-banner-title;
            margin:0 auto;

            p {
                color: $blog-banner-text;
            }
        }
    }


    article {
        margin-bottom:30px;

        .background-center {
            &:before {
                content:none;
            }
        }

        a {
            color: inherit;
            &:hover {
                text-decoration:none;
            }
        }    
   
        .article-image {
            height: $article-image-height-xs;
            border:15px solid #eee;
            border-bottom-width:0;

            @media(min-width:$screen-sm) { 
                height: $article-image-height-sm;
            }
            @media(min-width:$screen-md) { 
                height: $article-image-height-md;          
                margin-right:-15px;
                border-bottom-width:15px;
                border-right:none;
            }
        }

        .article-text {
            padding: 0 15px 15px 15px;
            background: #eee;

            @media(min-width:$screen-md) {
                margin-left:-15px;
                min-height: $article-image-height-md;
                display:flex;
                flex-direction:column;
                justify-content: space-between;
                padding:15px;
            }

            .article-title {
                margin-top:0;
                padding-top:15px;
                font-size:21px;
                color:$blog-primary;
                &:hover {
                    color:$blog-primary-hover;
                }

                @media(min-width:$screen-md) {
                    padding-top:0;
                }
            }

            p {      
                font-size:$article-p-size;
                line-height: 20px;
            }           
        }
    }

    .pagination {
        ul {
            display:flex;
            flex-direction:row;
            flex-wrap: wrap;
            border-radius: 5px;
            overflow:hidden;
            & > li {
                border: 1px solid $blog-gray-light;
                display:flex;

                &:not(:last-child) {
                    border-right:none;
                }

                &.active {
                    background: $blog-gray-light;
                }


                a {
                    padding:5px 15px;
                    &:hover {
                        text-decoration:none;                       
                    }
                }
            }
        }
    }

    .input-group {
        display:flex;
        flex-direction:row;
    }

    .input-group-addon {
        padding:0;
        min-width:100px;
        display:inline-flex;
        flex-grow:1;

        .btn {
            width:100%;
        }
    }
}

.article-list, .article {
    .author {
        display:flex;
        margin-top:10px;
                  
        img {
            margin-right: 10px;
            border-radius: 100%;
            width: $author-thumbnail;
            height: $author-thumbnail;
        }  
            
        &-details {
            display:flex;
            flex-direction:column;
            justify-content:center;
        }

        .post-time, p {
            color:$blog-gray;
        }

        .post-time, .author-name {
            font-size: $author-font-size;
        }  
    }
}