@font-face {
    font-family: 'Alegreya Sans';
    src: url('fonts/alegreyasans-bolditalic.woff2') format('woff2'), url('fonts/alegreyasans-bolditalic.woff') format('woff'), url('fonts/alegreyasans-bolditalic-webfont.eot');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Alegreya Sans';
    src: url('fonts/alegreyasans-italic.woff2') format('woff2'), url('fonts/alegreyasans-italic.woff') format('woff'), url('fonts/alegreyasans-italic-webfont.eot');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Alegreya Sans';
    src: url('fonts/alegreyasans-regular.woff2') format('woff2'), url('fonts/alegreyasans-regular.woff') format('woff'), url('fonts/alegreyasans-regular-webfont.eot');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Alegreya Sans';
    src: url('fonts/alegreyasans-bold.woff2') format('woff2'), url('fonts/alegreyasans-bold.woff') format('woff'), url('fonts/alegreyasans-bold-webfont.eot');
    font-weight: 700;
    font-style: normal;
}
