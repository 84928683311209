html, body {
    font-family: "Alegreya Sans", arial, sans-serif;
}


h2 {
    color: $primary;
}

p {
    margin-bottom: 15px;
}
