$authors-list-thumbnail: 100px;

.blog-authors {
    article {
        padding:10px;
        border: 1px solid $blog-gray-light;
        margin-bottom:15px;
    }

    .author {
        display:flex;

        .author-name {
            font-size: 21px;
            margin-bottom:10px;
        }
                  
        img {
            margin-right: 10px;
            border-radius: 100%;
            width: $authors-list-thumbnail;
            height: $authors-list-thumbnail;
        }  
            
        &-details {
            display:flex;
            flex-direction:column;
            justify-content:center;
        }
    }
}