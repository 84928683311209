.btn {
    border: 2px solid;
    white-space: normal;
    font-size: 20px;
    font-weight: 700;
    padding: 10px 15px;
    transition: .3s ease;


    &-primary {
        color: $white;
        background: $primary;
        border-color: $primary;

        &:hover, &:active, &:focus, &:active:focus, &:active:hover, &[disabled], &[disabled]:hover {
            background: darken($primary, 10%);
            border-color: darken($primary, 10%);
        }
    }

    &-default {
        color: $primary;
        border-color: $white;

        &:hover, &:active, &:focus, &:active:focus, &:active:hover, &[disabled], &[disabled]:hover {
            background: $primary;
            color: $white;
            border: 2px solid $white;
        }
    }

    &-info {
        &:hover, &:active, &:focus, &:active:focus, &:active:hover, &[disabled], &[disabled]:hover {
        }
    }
}
