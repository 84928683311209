$authors-list-thumbnail: 100px;

.blog-author {
    .author-content {
        img {
            margin-bottom:10px;
        }
    }
    .article-list {
        .author-image {
            display:none;
        }
        .author-name {
            display:none;
        }
    }
}