header {
    .top {
        background: $primary;
        padding: 10px;

        ul {
            margin: 0;

            li {
                margin-right: 20px;
                padding: 0;

                a {
                    font-size: 20px;
                    color: $white;
                    opacity: .5;
                    transition: .3s opacity;

                    &:hover {
                        opacity: 1
                    }
                }
            }
        }
    }

    .middle {
        border-bottom: 1px solid $gray-lighter;
        padding: 30px 0;

        .header-contact {
            .fa {
                display: block;
                color: $primary;
                margin-right: 10px;
                width: 36px;
                height: 36px;
                border-radius: 100%;
                border: 1px solid $primary;
                text-align: center;
                line-height: 35px;
                margin: 0 auto 10px;
            }

            ul {
                margin: 0;

                li {
                    font-size: 20px;
                    line-height: 1;
                    padding: 10px 0;

                    p {
                        text-align: center;
                    }

                    a {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    @media(min-width:$screen-sm) {
        .middle {
            .container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                .logo {
                    flex: 4;
                    margin-right:15px;

                    img {
                        margin: 0;
                        max-height:150px;
                    }
                }

                .header-contact {
                    flex: 6;
                }
            }

            .logo {
                img {
                    margin: 0 auto;
                }
            }

            .header-contact {
                .fa {
                    margin: 0 10px 0 0;
                    float: left;
                }

                ul {
                    li {
                        p {
                            text-align: left;
                        }
                    }
                }
            }
        }
    }

    @media(min-width:$screen-md) {
        .middle {
            .header-contact {
                ul {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    & > li {
                        flex: 1;
                    }
                }
            }
        }
    }

    /*@media(max-width:$screen-sm - 1) {
        .logo {
            margin-bottom:15px;
        }
    }*/
}
