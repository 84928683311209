.blog-container {
    max-width:1000px !important;

    &-sm {
        max-width:700px !important;
    }
}

@import "variables";
@import "article";
@import "article-list";
@import "blog-authors";
@import "blog-author";