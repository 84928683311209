.navbar {
    border-bottom-color: $gray-lighter;
}

@media(min-width:$navbar-mobile-breakpoint) {
    .navbar {
        background: $primary;
        border-bottom: 1px solid transparent;
        transition: .15s ease-in-out;
        .navbar-nav {
            & > li {
                & > a {
                    color: $white;

                    &:hover {
                        color: darken($white, 10%);
                    }
                }
            }
        }
    }
}

@media(max-width:$navbar-mobile-breakpoint - 1) {
    .navbar {
        background: #616162;
        border-bottom: none;

        button {
            color: $white;
            border: none;
            transition: .1s;

            span {
                transition: .1s;
            }

            &:hover, &:active, &:focus, &:active:focus, &:active:hover, &[disabled], &[disabled]:hover {
                background: none !important;

                span {
                    color: $primary;
                }
            }
        }

        .navbar-collapse {
            border-bottom: 1px solid $gray-lighter;

            ul {
                & > li {
                    & > a {
                        &:hover {
                            color: $white;
                            background: $primary;
                        }
                    }
                }
            }
        }
    }
}

.scrolled {
    .navbar{ 
        border-bottom: 1px solid $gray-lighter;
    }
}