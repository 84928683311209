$control-border-radius: 5px;
$modal-background-color: rgba(0, 0, 0, .85);
$modal-text-color: #fff;

.modal {
    overflow-y:scroll;
    z-index: 1050;
    overflow:hidden;
    background: $modal-background-color;

    &.active {
        display:block;
    }

    &-dialog {
        position:fixed;
        top: 50%;
        left:0;
        right:0;
        transform: translateY(-50%);
        margin-top:0;
        margin-bottom:0;
    }

    &-content {
        border-radius:0;
        color: $modal-text-color;
    }

    &-close {
        color: $modal-text-color;
        font-size:28px;
        line-height:28px;
        position:absolute;
        top:5px;
        right:15px;
        font-weight:bold;
        &:hover {
            cursor:pointer;
            text-decoration:none;
        }
    }

    &-controls {
        position:absolute;
        left:0;
        right:0;
        top:50%;
        transform:translateY(-50%);

        .fa {
            position:absolute;
            font-size: 24px;
            padding: 10px 5px;
            background: rgba(0,0,0, .6);
            &:hover {
                cursor:pointer;
            }
            &-chevron-right {
                right:0;
                border-top-left-radius: $control-border-radius;
                border-bottom-left-radius: $control-border-radius;
            }
            &-chevron-left {
                left:0;
                border-top-right-radius: $control-border-radius;
                border-bottom-right-radius: $control-border-radius;
            }
        }
    }
}