html, body {
    width: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    font-family: "Open Sans", arial, sans-serif;
    font-size: 16px;
    position: relative;
}

section {
    padding: 30px 0;
}

.background-center {
    background-position: center !important;
    background-size: cover !important;
    position: relative;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $overlay-background-color;
        opacity: .5;
        z-index: 0;
    }
}

.container {
    max-width:100%;
}