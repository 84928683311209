footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 30px 0;
    background: $footer-color;

    p {
        margin: 0;
    }

    .container {
        &:before, &:after {
            content: none;
        }
    }

    .flex-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        .social {
            display: flex;
            flex-wrap: wrap;
            margin: 0;

            & > li {
                margin: 0 10px;

                & > a {
                    display: flex;
                    align-items: center;

                    &:hover {
                        text-decoration:none;
                    } 

                    & > i {
                        font-size: 28px;
                    }
                }
            }
        }
    }
}
