.slick-slider {
    .slick-next {
        right: 0;
    }

    .slick-prev {
        left: 0;
    }

    .slick-next, .slick-prev {
        background: $slider-button-background;
        height: $slider-button-height;
        width: $slider-button-width;
        display: flex;
        justify-content: center;
        align-items: center;

        &:before {
            color: $slider-button-color;
            font-size: $slider-button-font-size;
            line-height: $slider-button-font-size;
        }
    }
}