.services-images {
    h3 {
        font-weight: 500;
        transition: .3s ease color;
    }

    img {
        margin: 0 auto;
        transition: .3s ease opacity;
        backface-visibility:hidden;
    }


    a {

        &:hover {
            text-decoration: none;
            
            h3 {
                color:$black;
            }

            img {
                opacity: .5;
            }
        }
    }
}

@media(min-width:$screen-sm) {
    .services-images {
        h2 {
            font-size: 44px;
        }
        h3 {
            font-size:28px;
        }
    }
}