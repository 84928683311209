.article {
    .blog-banner {
        margin-top:-30px;
        min-height:200px;
        background-size:cover;
        background-position-x: center;
        display:flex;
        align-items:center;

        @media(min-width: $screen-md) {
            min-height:400px;
        }
    }

    .article-title {
        color: $black;
    }

    .author-name {
        a {
            color:$blog-primary;
            &:hover {
                color:$blog-primary-hover;
            }
        }
    }

    .post-meta {
        ul {
            & > li {
                display:inline-block;
                margin-bottom:5px;

                a {
                    display:inline-block;
                    padding:5px 10px;
                    border:1px solid $blog-gray-light;
                    font-weight:700;
                    font-size: 12px;

                    &:hover {
                        text-decoration:none;
                    }
                }
            }
        }

        &.categories {
            a {
                border-color: $blog-primary;
                background: $blog-primary;
                color:$white;
                &:hover {
                    border-color:$blog-primary-hover;
                    background:$blog-primary-hover;
                }
            }
        }

        &.tags {
            a {
                background:$blog-gray-light;
                color:$black;
                &:hover {
                    border-color:$blog-secondary;
                    background:$blog-secondary;
                    color:$white;
                }
            }
        }
    }
}