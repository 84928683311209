$primary: #61AADC;
$secondary: #9D9C9C;
$white: #fff;
$black: #333;
/*$primary-gradient: linear-gradient(to right, #38ADED, #25629D);*/
//navbar
$navbar-height-mobile: 50px;
$navbar-height-desktop: 65px;
$navbar-font-size-mobile: 16px;
$navbar-font-size-desktop: 20px;

$navbar-mobile-breakpoint: $screen-sm;
$navbar-background: $white;

//navbar menu button
$navbar-mobile-button-margin-top: 5px;
$navbar-mobile-button-margin-sides: 10px;
$navbar-mobile-button-border-width: 1px;
$navbar-mobile-button-border: $navbar-mobile-button-border-width solid $gray-light;
$navbar-mobile-button-border-radius: 0;

$footer-color: $gray-lighter;

//overlays
$overlay-background-color: $black;

//slider
$slider-button-background: $white;
$slider-button-color: $black;
$slider-button-width: 30px;
$slider-button-height: 60px;
$slider-button-font-size: 20px;

//accordion
$accordion-container-margin: 30px 0;
$accordion-selector-color: $primary;
$accordion-icon-size: 12px;
$accordion-icon-width: 3px;
$accordion-selector-padding-top: 8px;
$accordion-selector-padding-sides: 15px;

//gallery
$gallery-container-margin: 30px 0;
$gallery-container-margin-mobile: 30px -15px;
$gallery-gutter: 15px;
$gallery-font-color: #333;
$gallery-item-overlay: rgba(#fff, .7);
$gallery-title-font-size: 24px;
$gallery-title-padding: 15px;

//sub pages
$sub-pages-container-margin: 30px 0;

//forms
$input-height: 34px;


/****  BOOTSTRAP OVERRIDES  ****/

// Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint: $navbar-mobile-breakpoint;
$navbar-height: $navbar-height-mobile;

// Grays
$gray-base: #000 !default;
$gray-darker: lighten($gray-base, 13.5%) !default; // #222
$gray-dark: lighten($gray-base, 20%) !default; // #333
$gray: lighten($gray-base, 33.5%) !default; // #555
$gray-light: lighten($gray-base, 46.7%) !default; // #777
$gray-lighter: lighten($gray-base, 93.5%) !default; // #eee