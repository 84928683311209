.form {
    //.form-control
    &-control {
        height: $input-height;
        border-radius:0;
    }

    label {
        display: block;
    }

    .input-group {
        position: relative;

        .btn {
            height: $input-height;
        }
    }
}

textarea {
    max-width:100%;
}