.two-column-list {

    @media(min-width:$screen-sm) {
        display: flex;
        flex-wrap: wrap;

        & > li {
            width: 50%;

            &:nth-child(2n + 1) {
                padding-right: 30px;
            }
        }
    }
}
