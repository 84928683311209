.article-list {
    .input-group-addon {
        border: none;

        .btn {
            font-size: 14px !important;
            border-radius: 0;
            padding: 5.5px 13px;
            border-width: 1px;
        }
    }
}
