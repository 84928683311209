footer {
    background: #313131;
    color: $white;
    padding-top: 0;

    a {
        color: $white;
        text-decoration: underline;

        &:hover {
            color: darken($white, 10%);
        }
    }

    h3 {
        color: $white;
    }

    .additional-info {
        padding: 30px 0;
        background: #3A3B3B;
    }

    .copyright {
        padding-top: 30px;

        ul {
            margin: 0;
            margin-top: 15px;

            li {
                margin-right: 20px;
                padding: 0;

                a {
                    font-size: 20px;
                    color: $white;
                    opacity: .5;
                    transition: .3s opacity;

                    &:hover {
                        opacity: 1
                    }
                }
            }
        }
    }

    .hours {
        padding-left: 45px;
        position: relative;

        &:before {
            content: '\f017';
            font-family: FontAwesome;
            position: absolute;
            top: 0;
            left: 0;
            color: $white;
            padding: 10px;
            border: 1px solid $white;
            width: 36px;
            height: 36px;
            line-height: 1;
            border-radius: 100%;
        }

        ul {

            li {
                margin-bottom: 15px;
            }
        }
    }

    .contact {
        & > li {
            line-height: 1;
            margin-bottom: 15px;
        }

        .phone, .email, .location {
            padding: 10px 0 10px 45px;
            position: relative;
            display: inline-block;
            margin: 0;

            &:before {
                font-family: FontAwesome;
                position: absolute;
                top: 0;
                left: 0;
                width: 36px;
                height: 36px;
                line-height: 1;
                border-radius: 100%;
                padding: 10px 9px;
                border: 1px solid $white;
                text-align: center;
                color: $white;
            }
        }

        .phone {
            &:before {
                content: '\f095';
            }
        }

        .email {
            &:before {
                content: '\f0e0 ';
            }
        }

        .location {
            &:before {
                content: '\f041  ';
            }
        }
    }
}

@media(min-width:$screen-sm) {
    footer {
        h3 {
            font-size: 26px;
        }

        .copyright {
            .container {
                position: relative;

                ul {
                    position: absolute;
                    right: 0;
                    top: 0;
                    margin: 0;
                }
            }
        }
    }
}

@media(max-width:$screen-sm - 1) {
    footer {
        .additional-info {
            text-align: center;
        }

        .hours {
            padding: 0;

            &:before {
                content: none;
            }
        }

        .copyright {
            text-align: center;
        }
    }
}
